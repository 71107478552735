/*global */
$( document ).ready( function() {
	$( '.menu > ul > li' ).each( ( i, elem ) => {
		const exist = $( elem ).find( '> .menu__submenu' );

		if ( exist.length ) {
			elem.classList.add( 'menu-dropdown-icon' );

			const items = $( elem ).find( '.menu__submenu > ul > li' );

			items.each( ( i, elem ) => {
				const card = $( elem ).find( '> a' );
				const img = card.find( 'img' );
				const delay = i * 100;
				card.css( 'transition-delay', `${ delay }ms` );
				img.css( 'transition-delay', `${ delay }ms` );
			});

			// const borderLine = document.createElement( 'div' );
			// borderLine.classList.add( 'border-line' );
			// elem.appendChild( borderLine );
		}

	});

	//Checks if li has sub (ul) and adds class for toggle icon - just an UI
	$( '.menu > ul > li > .menu__submenu' ).each( ( i, elem ) => {
		const exist = $( elem ).find( '> ul' );

		if ( !exist.length ) {
			elem.classList.add( 'normal-sub' );
		}

	});

	//Checks if drodown menu's li elements have anothere level (ul), if not the dropdown is shown as regular dropdown, not a mega menu (thanks Luka Kladaric)

	$( '.menu > ul' ).before( '<a href="#" class="menu-mobile">Navigation</a>' );

	//Adds menu-mobile class (for mobile toggle menu) before the normal menu
	//Mobile menu is hidden if width is more then 959px, but normal menu is displayed
	//Normal menu is hidden if width is below 959px, and jquery adds mobile menu
	//Done this way so it can be used with wordpress without any trouble

	function setMargins() {
		const submenus = $( '.menu > ul > li > .menu__submenu > ul' );
		submenus.each( ( i, elem ) => {
			if ( $( elem ).find( '> li' ).length > 5 && window.innerWidth < 1440 ) {
				$( elem ).find( '> li' ).addClass( 'small-margin' );
			} else {
				$( elem ).find( '> li' ).removeClass( 'small-margin' );
			}
		});
	}

	setMargins();

	window.addEventListener( 'resize', setMargins );

	let activeMenuItem;
	let timeOut;
	$( '.menu > ul > li' ).hover(
		function( e ) {
			if ( $( window ).width() > 943 ) {
				if ( activeMenuItem ) {
					clearTimeout( timeOut );
					$( activeMenuItem ).children( '.menu__submenu' ).fadeOut( 150 );
					$( activeMenuItem ).removeClass( 'menu-active' );
				}
				$( this ).children( '.menu__submenu' ).fadeIn( 150 );
				$( this ).addClass( 'menu-active' );
				activeMenuItem = this;
				e.preventDefault();
			}
		},
		function( e ) {

			if ( $( window ).width() > 943 ) {
				timeOut = setTimeout( () => {
					$( this ).children( '.menu__submenu' ).fadeOut( 150 );
					$( this ).removeClass( 'menu-active' );
					e.preventDefault();
				}, 1000 );

			}
		}
	);

	//If width is more than 943px dropdowns are displayed on hover

	//the following hides the menu when a click is registered outside
	$( document ).on( 'click', function( e ) {
		if ( $( e.target ).parents( '.menu' ).length === 0 ) {
			$( '.menu > ul' ).removeClass( 'show-on-mobile' );
		}
	});

	$( '.menu > ul > li' ).click( function() {

		//no more overlapping menus
		//hides other children menus when a list item with children menus is clicked
		var thisMenu = $( this ).children( '.menu__submenu' );
		var prevState = thisMenu.css( 'display' );

		$( '.menu > ul > li > .menu__submenu' ).fadeOut();
		if ( $( window ).width() < 943 ) {
			if ( prevState !== 'block' ) {
				thisMenu.fadeIn( 150 );
			}
		}
	});

	//If width is less or equal to 943px dropdowns are displayed on click (thanks Aman Jain from stackoverflow)

	$( '.menu-mobile' ).click( function( e ) {
		$( '.menu > ul' ).toggleClass( 'show-on-mobile' );

		// e.preventDefault();
	});

	//when clicked on mobile-menu, normal menu is shown as a list, classic rwd menu story (thanks mwl from stackoverflow)
});
